body {
  color: white!important;
}
a {
  color: #FFE3F6!important;
}
a:hover {
  color: #4A306D!important;
}

.nav-color {
  background-color: #C89FDA;
  margin-bottom: 18px;
}
.homeHead {
  font-family: 'Allura', cursive;
  font-size: 50px;
  position: relative; top: 10px;
  color: white!important;
  z-index: 1;
}
.nav-link {
  color: white!important;
}
.nav-link:hover {
  color: #4A306D!important;
}

.caraImg {
  width: 100%;
  aspect-ratio: 3 / 2!important;
}
.rowSpace {
  margin: 18px 0px!important;
}
.HomeShopCard {
  margin: 10px 0px!important;
  background-color: #4A306D!important;
  color: white!important;
  padding: 3px;
}
.HomeRighthandCard {
  background-color: black!important;
}
.HomeLefthandCardImg {
  width: 60%;
}
.featured {
  color: white!important;
  font-family: 'Allura', cursive;
  font-size: 40px;
  border: 1px solid #C89FDA!important;
}
.featuredBtn {
  background-color: #C89FDA!important;
  border-color: #4A306D!important;
}
.infoCard {
  background-color: black!important;
  color: white!important;
  border: 1px solid #C89FDA!important;
  margin: 18px 0px;
}
.footerL {
  display: flex;
  flex-direction: row-reverse;
}
.footerR {
  position: relative; top: 48px;
}


/*Bio*/
.bioRow {
  padding: 18px!important;
}
.willisCanadaBioImg {
  height: 100%;
  margin-top: 100px!important;
}
.bioHeading {
  font-family: 'Allura', cursive;
  font-size: 40px;
}

/*Photos*/
.photosImg {
  width: 100%;
  margin: 10px 5px!important;
  position: relative; left: -5px!important;
}

/* Events */
.event {
  border: 1px solid #C89FDA!important;
  margin: 18px 0px;
}
.textInEvent {
  margin: 10px;
  text-align: left;
}
.eventHeaderOver {
  border: 1px solid #C89FDA!important;
  width: 200px;
  margin: 20px;
}
.eventHeader {
  font-family: 'Allura', cursive;
  font-size: 50px;
  margin-top: 10px!important;
}

/* Contact */
.OverContactInfo {
  border: 1px solid #C89FDA!important;
}
.contactInfo {
  margin: 15px;
}
.willisHeadShot {
  height: 200px!important;
}
.flyer {
  height: 200px!important;
}

/* Admin Featured */
.adminShopCards {
  text-align: left;
}
.fbtn {
  margin: 0px 20px;
}

/* Edit Item */
.cashs {
  font-size: 25px;
}

/* Admin Posts */
.adminPosts {
  text-align: left;
}

/* admin slide */
.editSlideImg {
  width: 100%;
}
.slideMessage {
  margin-top: 10px!important;
}
.slideMsg {
  border: 1px solid #C89FDA!important;
  padding: 10px;
}
.slideBtns {
  margin: 8px 0px!important;
}

/* Admin Photos */
.editPhotoImg {
  width: 100%;
  display: block!important;
  margin-left: auto!important;
  margin-right: auto!important;
}

.storePhoto {
  border: 1px solid #C89FDA!important;
  padding: 80px;
  margin-top: 20px;
}
.buyButtonNoImg {
  margin-top: 50px!important;
}

.btn-primary {
  background-color: #4A306D!important;
  border: 3px solid rgb(189, 75, 255)!important;
}
.btn-primary:hover {
  background-color: rgb(189, 75, 255)!important;
  border: 3px solid rgb(189, 75, 255)!important;
}


.btn-primary a {
  color: black!important;
}

/* Guest book */
.gbHeaderOver {
  border: 1px solid #C89FDA!important;
  width: 300px;
  margin: 20px;
}
.gbHeader {
  font-family: 'Allura', cursive;
  font-size: 50px;
  margin-top: 10px!important;
}
.inputGB {
  display: inline-block;
}
.gbPost {
  margin: 15px;
}
.overGbPost {
  border: 1px solid #C89FDA!important;
}
.gbbtn2 {
  margin-top: 15px!important;
}


@media (max-width: 500px) {
  .footerR {
    position: relative; top: 0px;
  }
}

@media (max-width: 768px) {
  .HomeRighthandCard {
    margin-top: 18px!important;
  }
  
}

@media (min-width: 768px) {
  .homeHead {
    font-size: 65px;
  }
}